<template>
  <dialog-base
    v-model="dialogOpenCmp"
    height="auto"
  >
    <template #title>
      隐私政策
    </template>

    <template #body>
      <div
        style="white-space: break-spaces;"
      >
        <div
          class="d-flex flex-column justify-center align-end"
        >
          <div>更新日期：2023年10月20日</div>
          <div>生效日期：2023年11月30日</div>
        </div>
        <div v-html="privacyText"></div>
        <div
          class="d-flex flex-column justify-center align-end"
        >
          <h4>杭州小五和她的兄弟们科技有限公司</h4>
        </div>
      </div>
    </template>

    <template #actions>
      <v-spacer></v-spacer>

      <v-btn
        @click="dialogOpenCmp = false"
      >
        关闭
      </v-btn>
    </template>
  </dialog-base>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import DialogBase from '@/views/components/dialog-base/DialogBase.vue'

export default {
  name: 'PrivacyPolicy',
  components: {
    DialogBase,
  },
  model: {
    prop: 'dialogOpen',
    event: 'update:dialog-open',
  },
  props: {
    dialogOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const dialogOpenCmp = computed({
      get: () => props.dialogOpen,
      set: val => {
        emit('update:dialog-open', val)
      },
    })

    const privacyText = ref(
      '【引言】 \n'
      + '       欢迎您使用我们的产品和服务！我们深知个人信息对您的重要性，为了帮助您了解我们如何收集、处理和保护您的个人信息以及您可以如何管理您的个人信息，我们制定了本《隐私政策》（“本政策”）。在使用我们的产品和服务前，请您务必先仔细阅读本政策，特别是以粗体/粗体下划线标识的条款，确保您充分理解和同意之后再开始使用。如对本政策有任何疑问、意见或建议，您可通过本政策文末的联系方式与我们联系。\n'
      + '<h4>       您同意隐私政策表示您已了解应用提供的基本功能，以及基本功能运行所需的必要个人信息，并给予相应的收集使用授权，但并不代表您已单独同意开启附加功能、处理非必要个人信息。如开启附加功能、处理非必要个人信息以及处理敏感个人信息，我们会根据您的实际使用情况单独征求您的同意。</h4>'
      + '本政策将帮助您了解以下内容：\n'
      + '一、收集信息\n'
      + '二、使用信息\n'
      + '三、公享信息\n'
      + '四、信息披露与保护\n'
      + '五、存储信息\n'
      + '六、未成年人保护\n'
      + '七、联系我们\n'
      + '八、其他\n'
      + '<h4>1. 收集信息</h4>'
      + '我们收集您在使用我们的产品和服务时提供的信息，包括但不限于以下内容：\n'
      + '<h4>姓名/企业名称</h4>'
      + '<h4>身份证号/统一社会信用代码/工商注册号</h4>'
      + '<h4>法定代表人/负责人姓名</h4>'
      + '<h4>手机号码</h4>'
      + '<h4>人脸采集信息</h4>'
      + '<h4>2. 使用信息</h4>'
      + '<h4>我们向您收集第1条所述信息用于完成您的身份验证以及您的社交平台账号实名认证等。</h4>'
      + '<h4>为了向您提供我们的产品或服务的基本功能，您必须授权我们收集、使用的必要信息。如您拒绝，您将无法正常使用我们的产品或服务。</h4>'
      + '您有权访问您的个人信息，并要求我们更正不正确的信息。如果您想行使这些权利，请联系我们。\n'
      + '<h4>3. 共享信息</h4>'
      + '除以下情形外，未经您本人明示同意，我们不会与任何第三方分享您的个人信息：\n'
      + '我们可能将您的个人信息与第三方服务供应商、承包商及代理（例如代表我们发出电子邮件或推送通知的通信服务提供商、为我们提供位置数据的地图服务供应商）分享无须征求您的授权同意，用作下列用途：\n'
      + 'a）向您提供我们的服务；\n'
      + 'b）实现“使用信息”部分所述目的；\n'
      + 'c）履行我们在本“隐私政策”中的义务和行使我们的权利；\n'
      + 'd）理解、维护和改善我们的服务。\n'
      + '如我们与任何第三方分享您的个人信息，我们将努力确保该等第三方在使用您的个人信息时遵守本“隐私政策”及我们要求其遵守的其他适当的保密和安全措施。\n'
      + '<h4>4. 信息披露与保护</h4>'
      + '目前，我们不会主动共享或转让您的个人信息给其他任何第三方平台，如存在其他共享或转让您的个人信息或您需要我们将您的个人信息共享或转让至其他第三方的情形时，我们会直接征得您的明示同意并确认第三方已征得您的明示同意。我们将尽一切可能采取适当的技术手段，保证您可以访问、更新和更正自己的注册信息或使用我们的服务时提供的其他个人信息。在访问、更新、更正和删除前述信息时，我们可能会要求您进行身份验证，以保障账户安全。\n'
      + '根据相关法律法规及国家标准，以下情形中，我们可能会收集、使用您的相关个人信息无需征求您的授权同意：\n'
      + 'a）与个人信息控制履行法律法规规定的义务相关的；\n'
      + 'b）与国家安全、国防安全直接相关的；\n'
      + 'c）与公共安全、公共卫生、重大公共利益直接相关的；\n'
      + 'd）与刑事侦查、起诉、审判和判决执行等直接相关的；\n'
      + 'e）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；\n'
      + 'f）所涉及的个人信息是个人信息主体自行向社会公共公开的；\n'
      + 'g）根据个人信息主体要求签订和履行合同所必需的；\n'
      + 'h）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；\n'
      + 'i）维护所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障；\n'
      + 'j）个人信息控制为新闻单位，且其开展合法的新闻报道所必需的；\n'
      + 'k）个人信息控制者为学术研究机构，出于公共利益开展统计或学术研究所必要，且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的。'
      + '<h4>5. 存储信息</h4>'
      + '<h4>我们将在合理的时间内保留您的个人信息，我们只会在达成本政策所述目的所需的期限内保留您的个人信息（除非法律有强制的存留要求）。</h4>'
      + '我们使用各种安全技术和程序，以防信息的丢失、不当使用、未经授权阅览或披露。例如，在某些服务中，我们将利用加密技术（例如SSL）来保护您提供的个人信息。\n'
      + '我们通过不断提升的技术手段加强安装在您设备端的软件的安全能力，以防止您的个人信息泄露。\n'
      + '<h4>6. 未成年保护</h4>'
      + '我们非常重视对未成年人个人信息的保护。根据相关法律法规的规定，若您是18周岁以下的未成年人，在使用本应用服务前，应事先取得您的家长或法定监护人的书面同意。若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请通过第7条中的联系方式与我们联系。\n'
      + '<h4>7. 联系我们</h4>'
      + '如果您有任何关于隐私政策的问题或疑虑，请通过以下方式联系我们：\n'
      + '电话：0571-88308005\n'
      + '<h4>8. 其他</h4>'
      + '本隐私政策可能会随着时间的推移而更新。在使用我们的产品和服务时，您应定期查看本隐私政策。如果您不同意任何更新的条款，您应停止使用我们的产品和服务。\n',
    )

    return {
      dialogOpenCmp,
      privacyText,
    }
  },
}
</script>

<style scoped>

</style>
