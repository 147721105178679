<template>
  <v-btn-toggle
    v-if="!getDataWithKey(btnToggleProps.hidden)"
    v-model="data"
    v-bind="btnToggleProps"
  >
    <v-btn
      v-for="(item, index) in btnList"
      :key="`${item.value}-${index}`"
      :color="item.color"
      :value="item.value"
      v-bind="item.componentProps"
      v-on="item.eventHandlers"
    >
      <v-icon
        v-if="item.icon"
        v-bind="item.iconProps"
      >
        {{ getDataWithKey(item.icon, 'value') }}
      </v-icon>
      {{ getDataWithKey(item.label, 'value') }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
import { computed } from '@vue/composition-api'
import { getDataWithKey } from '@core/utils/dataProcess'

export default {
  name: 'BtnToggleGroup',
  model: {
    prop: 'dataModel',
    event: 'update:data-model',
  },
  props: {
    dataModel: {
      type: [Boolean, Number, String, Object, Array, null],
      default: 0,
    },
    label: {
      type: String,
      default: '请选择：',
    },
    btnToggleProps: {
      type: Object,
      default: Object,
    },
    btnList: {
      type: Array,
      default: Array,
    },
  },
  setup(props, { emit }) {
    const data = computed({
      get: () => props.dataModel,
      set: val => emit('update:data-model', val),
    })

    return {
      data,
      getDataWithKey,
    }
  },
}
</script>

<style scoped>

</style>
