<template>
  <v-dialog
    :value="dialogOpenModel"
    :max-width="width"
    scrollable
    :persistent="persistent"
    @click:outside="persistent ? void 0 : $emit('update:dialog-open-model',false)"
  >
    <v-card>
      <v-card-title class="cardTitle justify-center text-h5">
        <slot name="title"></slot>

        <v-btn
          v-if="!hideClose"
          icon
          small
          title="关闭"
          class="closeIcon"
          @click="$emit('update:dialog-open-model',false)"
        >
          <v-icon size="22">
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text
        :class="bodyClass"
        :style="`height: ${height};`"
      >
        <slot name="body"></slot>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogBase',
  model: {
    prop: 'dialogOpenModel',
    event: 'update:dialog-open-model',
  },
  props: {
    dialogOpenModel: {
      type: Boolean,
      default: false,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      required: false,
      default: false,
    },
    bodyClass: {
      type: [String, Array, Object],
      default: '',
    },
    height: {
      type: String,
      required: false,
      default: '400px',
    },
    width: {
      type: String,
      required: false,
      default: '800px',
    },
  },
  setup() {

  },
}
</script>

<style scoped>
.cardTitle {
  position: relative;
}
.closeIcon {
  position: absolute;
  right: 20px;
}
</style>
