<template>
  <dialog-base
    v-model="dialogOpenCmp"
    persistent
    width="600px"
    height="auto"
  >
    <template #title>
      请打开手机支付宝扫码认证
    </template>

    <template #body>
      <div
        class="d-flex justify-center"
      >
        <canvas ref="qrcode"></canvas>
      </div>
    </template>

    <template #actions>
      <v-spacer></v-spacer>
      <v-btn
        @click="dialogOpenCmp = false"
      >
        关闭
      </v-btn>

      <v-btn
        color="success"
        @click="checkAuth"
      >
        确认实名状态
      </v-btn>
    </template>
  </dialog-base>
</template>

<script>
import { requestGet } from '@core/utils/form'
import {
  ref, computed, watch, nextTick,
} from '@vue/composition-api'
import { modalFail, toastInfo, toastSuccess } from '@core/utils/prompt'
import { getParsedUserData, refreshUserInfo } from '@core/utils/userData'
import DialogBase from '@/views/components/dialog-base/DialogBase.vue'

export default {
  name: 'QRCodeDialog',
  components: {
    DialogBase,
  },
  model: {
    prop: 'dialogOpen',
    event: 'update:dialog-open',
  },
  props: {
    dialogOpen: {
      type: Boolean,
      default: false,
    },
    qrcodeSrc: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const qrcode = ref(null)
    const QRCode = require('qrcode')
    const dialogOpenCmp = computed({
      get: () => props.dialogOpen,
      set: val => emit('update:dialog-open', val),
    })

    const checkAuth = () => {
      requestGet('/client/userInfo').then(res => {
        if (res.data.cpType === 1 ? getParsedUserData(res.data).contractPersonAuth : getParsedUserData(res.data).auth) {
          toastSuccess('认证成功！')
          dialogOpenCmp.value = false
          refreshUserInfo()
        } else toastInfo('认证失败，请确认是否认证成功！')
      })
    }

    watch(() => props.qrcodeSrc, () => {
      nextTick(() => {
        QRCode.toCanvas(qrcode.value, props.qrcodeSrc, error => {
          if (error) modalFail('获取二维码失败，请重试！')
        })
      })
    })

    return {
      qrcode,
      QRCode,
      dialogOpenCmp,
      checkAuth,
    }
  },
}
</script>

<style scoped>

</style>
