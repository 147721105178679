<template>
  <div
    class="d-flex"
  >
    <div
      class="d-flex cursorHoverPointer"
    >
      <div
        class="me-2"
      >
        <v-img
          width="45px"
          height="45px"
          :src="svgSrc"
        ></v-img>
      </div>

      <div
        class="d-flex flex-column"
      >
        <h4
          v-if="title"
        >
          {{ title }}
        </h4>

        <div
          v-if="content"
        >
          {{ content }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Alipay',
  props: {
    svgSrc: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped>

</style>
